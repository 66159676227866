<template>
  <section class="footer__section">
    <h2 class="visually-hidden">Direkteinstiege</h2>
    <div class="footer__content content__area">
      <ul v-if="showLinks" class="footer-links">
        <li
          v-for="(column, columnIndex) in links"
          :key="`column-${columnIndex}`"
          class="footer-column footer-column__links"
        >
          <div class="footer-column__content">
            <h3 v-if="column.title" class="footer-column__header">
              {{ column.title }}
            </h3>
            <ul
              class="footer-links__list"
              :class="{ 'row-layout': $brand.isFonic() }"
            >
              <li
                v-for="(firstLevelItem, firstLevelItemIndex) in column.items"
                :key="`first-level-${firstLevelItemIndex}`"
                class="footer-links__item"
              >
                <router-link
                  v-if="firstLevelItem.name"
                  class="footer__link"
                  :to="firstLevelItem"
                  :data-cy="`footer-link-${firstLevelItem.name}`"
                  >{{ firstLevelItem.title }}</router-link
                >
                <a
                  v-else
                  class="footer__link"
                  :href="firstLevelItem.href"
                  :target="firstLevelItem.target"
                  :rel="firstLevelItem.rel"
                  v-html="firstLevelItem.title"
                />
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <ul class="footer__legal">
        <li class="footer__legal__item">
          <a
            v-if="$brand.isFonic()"
            class="footer__legal__link"
            href="https://www.fonic.de/impressum/"
            target="_blank"
            rel="noopener noreferrer"
            >Impressum</a
          >
          <router-link v-else class="footer__legal__link" to="/impressum"
            >Impressum</router-link
          >
        </li>
        <li class="footer__legal__item">
          <a
            v-if="$brand.isFonic()"
            class="footer__legal__link"
            href="https://www.fonic.de/infopflicht/"
            target="_blank"
            rel="noopener noreferrer"
            >Rechtliches</a
          >
          <router-link v-else class="footer__legal__link" to="/infopflicht"
            >Rechtliches</router-link
          >
        </li>
        <li class="footer__legal__item">
          <router-link class="footer__legal__link" to="/datenschutz"
            >Datenschutz</router-link
          >
        </li>
        <li class="footer__legal__item">
          <a
            v-if="$brand.isFonic()"
            class="footer__legal__link"
            href="https://www.fonic.de/agb-und-infos/"
            target="_blank"
            rel="noopener noreferrer"
            >AGB &amp; Pflichtinformationen</a
          >
          <router-link v-else class="footer__legal__link" to="/agb-und-infos"
            >AGB &amp; Pflichtinformationen</router-link
          >
        </li>
        <li class="footer__legal__item">
          <a
            v-if="$brand.isFonic()"
            href="https://www.fonic.de/content/dam/fonic/documents/FONIC-Widerrufsformular.pdf"
            class="footer__legal__link"
            target="_blank"
            >Widerrufsbelehrung als PDF</a
          >
          <a
            v-else
            class="footer__legal__link"
            :href="$brand.cancellationPolicyLink"
            target="_blank"
            >Widerrufsbelehrung als PDF</a
          >
        </li>
        <li class="footer__legal__item">
          <a
            v-if="$brand.isFonic()"
            class="footer__legal__link"
            href="https://kuendigung.fonic.de/"
            target="_blank"
            rel="noopener noreferrer"
            >Vertrag kündigen</a
          >
          <a
            v-else
            class="footer__legal__link"
            href="https://kuendigung.fonic-mobile.de"
            target="_blank"
            rel="noopener noreferrer"
            >Vertrag kündigen</a
          >
        </li>
        <li class="footer__legal__item">
          <a
            v-if="$brand.isFonic()"
            class="footer__legal__link"
            href="https://www.fonic.de/cookies.html"
            target="_blank"
            rel="noopener noreferrer"
            >Cookies</a
          >
          <router-link v-else class="footer__legal__link" to="/cookies">
            Cookies
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import links_json from './links.json'
export default {
  name: 'FooterLinks',
  props: {
    showLinks: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      links: this.$brand.isFonic() ? links_json.fonic : links_json.fonic_mobile
    }
  }
}
</script>

<style lang="scss" scoped>
.footer__section {
  padding: 40px 0 1rem;
  text-align: center;
}

.footer__content {
  padding: 0 20px;
}

.footer-links {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-bottom: 24px;

  @include breakpoint($from-large-tablet) {
    flex-flow: row wrap;
    justify-content: space-around;
    list-style-type: none;
    text-align: left;
    gap: 120px;

    @include fonic-mobile {
      justify-content: space-between;
    }
  }
}

.footer-column {
  color: inherit;
  display: flex;
  justify-content: center;
}

.footer-column__links {
  display: none;

  @include breakpoint($from-large-tablet) {
    display: flex;
  }
}

.footer-column__content {
  margin: 0 auto;
}

.footer-links__list {
  padding-left: 0;
}

.footer-column__header {
  @include font-bold;

  font-size: inherit;
  margin: 0;
}

.footer-links__item {
  padding: 0.25rem 0;
  list-style-type: none;

  .footer__link {
    text-decoration: none;
    color: $footer-color;

    @include fonic-mobile {
      color: var(--root-color);
    }
  }
}

.fb-share-button {
  vertical-align: middle;
  top: -5px;
}

.footer__legal {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  font-size: calc-rem(14px);
  padding-left: 0;
  column-gap: 10px;

  @include breakpoint($from-tablet) {
    justify-content: space-between;
  }
}

.footer__legal__item {
  display: inline-block;
  line-height: 1;
  margin-bottom: 1rem;
}

.footer__legal__link {
  line-height: 1;
  text-decoration: none;
  color: $footer-color;

  @include fonic-mobile {
    color: var(--root-color);
  }
}

.row-layout {
  @include breakpoint($from-desktop) {
    display: flex;
    gap: 50px;
    margin: 10px 0;
  }
}
</style>
