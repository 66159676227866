const SEOLinks = {
  '/service': [
    {
      url: '/service/faq/vertrag/tarif-wechseln',
      text: 'Tarifwechsel'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/fonic-app',
      text: 'App'
    },
    {
      url: '/service/faq/technik/netz',
      text: 'Netz'
    },
    {
      url: '/service/faq/technik/handy-ortung',
      text: 'Handyortung'
    },
    {
      url: '/service/faq/sim-karte/nano-sim',
      text: 'Nano SIM'
    },
    {
      url: '/service/faq/sim-karte/sim-karte-sperren',
      text: 'SIM sperren'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/kontakt',
      text: 'Kontakt'
    }
  ],
  '/service/faq': [
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    { url: '/service/faq/vertrag/rechnung', text: 'Rechnung' },
    {
      url: '/service/faq/technik/geraete-einstellungen',
      text: 'Geräteeinstellung'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/sim-karte/pin-und-puk',
      text: 'PIN | PUK'
    },
    {
      url: '/service/faq/sim-karte/sim-karte-sperren',
      text: 'SIM sperren'
    },
    {
      url: '/service/faq/technik/handy-ortung',
      text: 'Handyortung'
    }
  ],
  '/service/faq/sim-karte': [
    {
      url: '/service/faq/technik/geraete-einstellungen',
      text: 'Geräteeinstellung'
    },
    {
      url: '/service/faq/sim-karte/sim-karte-sperren',
      text: 'SIM sperren'
    },
    {
      url: '/service/faq/technik/handy-ortung',
      text: 'Handyortung'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/faq/vertrag/rufnummer-portieren',
      text: 'Rufnummerportierung'
    },
    {
      url: '/service/faq/ausland/ausland-roaming/roaming-rechner',
      text: 'Roaming Rechner'
    },
    {
      url: '/service/ueber-fonic',
      text: 'Über FONIC'
    }
  ],
  '/service/faq/sim-karte/ersatz-sim-karte': [
    {
      url: '/service/faq/technik/handy-ortung',
      text: 'Handyortung'
    },
    {
      url: '/service/faq/sim-karte/sim-karte-sperren',
      text: 'SIM sperren'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/vertrag/rufnummer-portieren',
      text: 'Rufnummerportierung'
    },
    {
      url: '/service/faq/vertrag/tarif-wechseln',
      text: 'Tarifwechsel'
    },
    {
      url: '/selfcare/service/formulare',
      text: 'Formulare'
    },
    {
      url: '/service/fonic-app',
      text: 'APP'
    },
    {
      url: '/service/kontakt',
      text: 'Kontakt'
    }
  ],
  '/service/faq/sim-karte/nano-sim': [
    {
      url: '/service/faq/sim-karte/pin-und-puk',
      text: 'PIN | PUK'
    },
    {
      url: '/service/faq/sim-karte/sim-karte-sperren',
      text: 'SIM Karte sperren'
    },
    {
      url: '/service/faq/technik/dienste-und-funktionen',
      text: 'Dienste & Funktionen'
    },
    {
      url: '/service/faq/technik/netz',
      text: 'Netz'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/ueber-fonic',
      text: 'Über FONIC'
    },
    {
      url: '/service/ueber-fonic/verkaufsstellen',
      text: 'Verkaufsstellen'
    }
  ],
  '/service/faq/sim-karte/pin-und-puk': [
    {
      url: '/service/faq/sim-karte/sim-karte-sperren',
      text: 'SIM sperren'
    },
    {
      url: '/service/faq/technik/handy-ortung',
      text: 'Handyortung'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/technik/netz',
      text: 'Netz'
    },
    {
      url: '/service/faq/technik',
      text: 'Technik'
    },
    {
      url: '/service/faq/technik/dienste-und-funktionen',
      text: 'Dienste & Funktionen'
    },
    {
      url: '/service/faq/technik/mailbox',
      text: 'Mailbox'
    },
    {
      url: '/service/faq/ausland/ausland-roaming',
      text: 'Ausland'
    },
    {
      url: '/service/faq/ausland/ausland-roaming/roaming-rechner',
      text: 'Roaming Rechner'
    }
  ],
  '/service/faq/sim-karte/sim-karte-sperren': [
    {
      url: '/service/faq/technik/handy-ortung',
      text: 'Handyortung'
    },
    {
      url: '/service/faq/technik/geraete-einstellungen',
      text: 'Geräteeinstellung'
    },
    {
      url: '/service/faq/technik/mailbox',
      text: 'Mailbox'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/vertrag/bankverbindung',
      text: 'Bankverbindung'
    },
    {
      url: '/service/fonic-app',
      text: 'APP'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    }
  ],
  '/service/faq/technik': [
    {
      url: '/service/faq/technik/handy-ortung',
      text: 'Handyortung'
    },
    {
      url: '/service/faq/technik/dienste-und-funktionen',
      text: 'Dienste & Funktionen'
    },
    {
      url: '/service/faq/technik/netz',
      text: 'Netz'
    },
    {
      url: '/service/faq/technik/mailbox',
      text: 'Mailbox'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/sim-karte/ersatz-sim-karte',
      text: 'Ersatz SIM Karte'
    },
    {
      url: '/service/faq/technik/geraete-einstellungen',
      text: 'Geräteeinstellung'
    },
    {
      url: '/service/faq/sim-karte/pin-und-puk',
      text: 'PIN | PUK'
    },
    {
      url: '/service/faq/vertrag/tarif-wechseln',
      text: 'Tarifwechsel'
    }
  ],
  '/service/faq/technik/dienste-und-funktionen': [
    {
      url: '/service/faq/technik/handy-ortung',
      text: 'Handyortung'
    },
    {
      url: '/service/faq/technik/mailbox',
      text: 'Mailbox'
    },
    {
      url: '/service/faq/sim-karte/sim-karte-sperren',
      text: 'SIM sperren'
    },
    {
      url: '/service/faq/technik/netz',
      text: 'Netz'
    },
    {
      url: '/service/faq/vertrag/rechnung',
      text: 'Rechnung'
    },
    {
      url: '/service/faq/vertrag/rufnummer-portieren',
      text: 'Rufnummerportierung'
    },
    {
      url: '/service/fonic-app',
      text: 'APP'
    }
  ],
  '/service/faq/technik/handy-ortung': [
    {
      url: '/service/faq/sim-karte/sim-karte-sperren',
      text: 'SIM sperren'
    },
    {
      url: '/service/faq/technik/geraete-einstellungen',
      text: 'Geräteeinstellung'
    },
    {
      url: '/service/faq/technik/netz',
      text: 'Netz'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/sim-karte/pin-und-puk',
      text: 'PIN | PUK'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/sim-karte/nano-sim',
      text: 'Nano SIM'
    },
    {
      url: '/service/kontakt',
      text: 'Kontakt'
    }
  ],
  '/service/faq/technik/mailbox': [
    {
      url: '/service/faq/technik',
      text: 'Technik'
    },
    {
      url: '/service/faq/technik/geraete-einstellungen',
      text: 'Geräteeinstellung'
    },
    {
      url: '/service/faq/technik/dienste-und-funktionen',
      text: 'Dienste & Funktionen'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/ueber-fonic/verkaufsstellen',
      text: 'Verkaufsstellen'
    },
    {
      url: '/service/faq/ausland/ausland-roaming/roaming-rechner',
      text: 'Roaming Rechner'
    },
    {
      url: '/service/faq/ausland/ausland-roaming',
      text: 'Ausland'
    }
  ],
  '/service/faq/technik/netz': [
    {
      url: '/service/faq/technik/telefonieren-und-surfen',
      text: 'Telefonieren & Surfen'
    },
    {
      url: '/service/faq/vertrag/tarif-wechseln',
      text: 'Tarifwechsel'
    },
    {
      url: '/service/faq/ausland/ausland-roaming',
      text: 'Ausland'
    },
    {
      url: '/service/faq/ausland/ausland-roaming/roaming-rechner',
      text: 'Roaming Rechner'
    },
    {
      url: '/service/ueber-fonic/verkaufsstellen',
      text: 'Verkaufsstellen'
    },
    {
      url: '/service/faq/vertrag/rufnummer-portieren',
      text: 'Rufnummerportierung'
    },
    {
      url: '/service/faq/vertrag/transparenzverordnung',
      text: 'Transparenzverordnung'
    }
  ],
  '/service/faq/technik/geraete-einstellungen': [
    {
      url: '/service/faq/technik',
      text: 'Technik'
    },
    {
      url: '/service/faq/technik/mailbox',
      text: 'Mailbox'
    },
    {
      url: '/service/faq/technik/dienste-und-funktionen',
      text: 'Dienste & Funktionen'
    },
    {
      url: '/service/faq/technik/netz',
      text: 'Netz'
    },
    {
      url: '/service/faq/technik/telefonieren-und-surfen',
      text: 'Telefonieren & Surfen'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/ueber-fonic',
      text: 'Über FONIC'
    },
    {
      url: '/selfcare/service/formulare',
      text: 'Formulare'
    }
  ],
  '/service/faq/technik/telefonieren-und-surfen': [
    {
      url: '/service/faq/vertrag/tarif-wechseln',
      text: 'Tarifwechsel'
    },
    {
      url: '/selfcare/service/formulare',
      text: 'Formulare'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/vertrag/rufnummer-portieren',
      text: 'Rufnummerportierung'
    },
    {
      url: '/service/faq',
      text: 'FAQs'
    },
    {
      url: '/service/faq/ausland/ausland-roaming',
      text: 'Ausland'
    },
    {
      url: '/service/ueber-fonic/verkaufsstellen',
      text: 'Verkaufsstellen'
    }
  ],
  '/service/faq/vertrag': [
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/vertrag/bankverbindung',
      text: 'Bankverbindung'
    },
    {
      url: '/service/faq/vertrag/rechnung',
      text: 'Rechnung'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/sim-karte/nano-sim',
      text: 'Nano SIM'
    },
    {
      url: '/selfcare/service/formulare',
      text: 'Formulare'
    }
  ],
  '/service/faq/vertrag/bankverbindung': [
    {
      url: '/service',
      text: 'Service'
    },
    {
      url: '/service/faq',
      text: 'FAQs'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/vertrag/rechnung',
      text: 'Rechnung'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/sim-karte/nano-sim',
      text: 'Nano SIM'
    }
  ],
  '/selfcare/service/formulare': [
    {
      url: '/service',
      text: 'Service'
    },
    {
      url: '/service/faq',
      text: 'FAQs'
    },
    {
      url: '/service/faq/vertrag/rufnummer-portieren',
      text: 'Rufnummerportierung'
    },
    {
      url: '/service/faq/vertrag/rechnung',
      text: 'Rechnung'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/fonic-app',
      text: 'APP'
    },
    {
      url: '/service/faq/vertrag/transparenzverordnung',
      text: 'Transparenzverordnung'
    }
  ],
  '/service/faq/vertrag/freunde-werben-freunde': [
    {
      url: '/service',
      text: 'Service'
    },
    {
      url: '/service/faq',
      text: 'FAQs'
    },
    {
      url: '/service/fonic-app',
      text: 'APP'
    },
    {
      url: '/service/ueber-fonic',
      text: 'Über FONIC'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/vertrag/transparenzverordnung',
      text: 'Transparenzverordnung'
    }
  ],
  '/service/faq/vertrag/guthaben': [
    {
      url: '/service',
      text: 'Service'
    },
    {
      url: '/service/ueber-fonic/verkaufsstellen',
      text: 'Verkaufsstellen'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/kontakt',
      text: 'Kontakt'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/fonic-app',
      text: 'APP'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/sim-karte/nano-sim',
      text: 'Nano SIM'
    }
  ],
  '/service/faq/vertrag/persoenliche-daten': [
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/faq/vertrag/bankverbindung',
      text: 'Bankverbindung'
    },
    {
      url: '/service/faq/vertrag/rechnung',
      text: 'Rechnung'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/fonic-app',
      text: 'App'
    },
    {
      url: '/service/faq/technik',
      text: 'Technik'
    },
    {
      url: '/service/faq/technik/dienste-und-funktionen',
      text: 'Dienste & Funktionen'
    },
    {
      url: '/service/faq/technik/mailbox',
      text: 'Mailbox'
    },
    {
      url: '/service/kontakt',
      text: 'Kontakt'
    }
  ],
  '/service/faq/vertrag/rechnung': [
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/faq/vertrag/bankverbindung',
      text: 'Bankverbindung'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/faq/ausland/ausland-roaming',
      text: 'Ausland'
    },
    {
      url: '/service/kontakt',
      text: 'Kontakt'
    }
  ],
  '/service/faq/vertrag/rufnummer-portieren': [
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/selfcare/service/formulare',
      text: 'Formulare'
    },
    {
      url: '/service/faq/technik',
      text: 'Technik'
    },
    {
      url: '/service/faq/technik/netz',
      text: 'Netz'
    },
    {
      url: '/service/faq/vertrag/tarif-wechseln',
      text: 'Tarifwechsel'
    }
  ],
  '/service/faq/vertrag/tarif-wechseln': [
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/faq/vertrag/bankverbindung',
      text: 'Bankverbindung'
    },
    {
      url: '/service/faq/vertrag/rechnung',
      text: 'Rechnung'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/ueber-fonic',
      text: 'Über FONIC'
    }
  ],
  '/service/faq/vertrag/transparenzverordnung': [
    {
      url: '/service',
      text: 'Service'
    },
    {
      url: '/service/faq',
      text: 'FAQs'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/technik',
      text: 'Technik'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/fonic-app',
      text: 'App'
    },
    {
      url: '/service/kontakt',
      text: 'Kontakt'
    },
    {
      url: '/service/ueber-fonic',
      text: 'Über FONIC'
    }
  ],
  '/service/fonic-app': [
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/vertrag/rechnung',
      text: 'Rechnung'
    },
    {
      url: '/service/faq/vertrag/bankverbindung',
      text: 'Bankverbindung'
    },
    {
      url: '/service/faq/technik',
      text: 'Technik'
    },
    {
      url: '/service/faq/technik/mailbox',
      text: 'Mailbox'
    }
  ],
  '/service/kontakt': [
    {
      url: '/service/faq/vertrag/tarif-wechseln',
      text: 'Tarifwechsel'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/faq/vertrag',
      text: 'Vertrag'
    },
    {
      url: '/service/faq/vertrag/persoenliche-daten',
      text: 'Persönliche Daten'
    },
    {
      url: '/service/faq/technik/mailbox',
      text: 'Mailbox'
    },
    {
      url: '/service/faq/vertrag/rechnung',
      text: 'Rechnung'
    }
  ],
  '/service/ueber-fonic': [
    {
      url: '/service',
      text: 'Service'
    },
    {
      url: '/service/faq',
      text: 'FAQs'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/sim-karte/ersatz-sim-karte',
      text: 'Ersatz SIM Karte'
    },
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/ueber-fonic/verkaufsstellen',
      text: 'Verkaufsstellen'
    },
    {
      url: '/service/faq/vertrag/transparenzverordnung',
      text: 'Transparenzverordnung'
    }
  ],
  '/service/ueber-fonic/verkaufsstellen': [
    {
      url: '/service/faq/vertrag/guthaben',
      text: 'Guthaben'
    },
    {
      url: '/service/ueber-fonic',
      text: 'Über FONIC'
    },
    {
      url: '/service/fonic-app',
      text: 'App'
    },
    {
      url: '/service/faq/vertrag/tarif-wechseln',
      text: 'Tarifwechsel'
    },
    {
      url: '/service/kontakt',
      text: 'Kontakt'
    },
    {
      url: '/service/faq/sim-karte',
      text: 'SIM Karte'
    },
    {
      url: '/service/faq/sim-karte/nano-sim',
      text: 'Nano SIM'
    },
    {
      url: '/service/faq/sim-karte/pin-und-puk',
      text: 'PIN | PUK'
    }
  ],
  '/service/faq/ausland/ausland-roaming/roaming-rechner': [
    {
      url: '/service/faq/ausland/ausland-roaming',
      text: 'Ausland'
    },
    {
      url: '/service/ueber-fonic/verkaufsstellen',
      text: 'Verkaufsstellen'
    },
    {
      url: '/service/fonic-app',
      text: 'App'
    },
    {
      url: '/service/faq/technik/netz',
      text: 'Netz'
    },
    {
      url: '/service/faq/technik/geraete-einstellungen',
      text: 'Geräteeinstellung'
    },
    {
      url: '/service/faq/technik/telefonieren-und-surfen',
      text: 'Telefonieren & Surfen'
    }
  ],
  '/service/faq/ausland/ausland-roaming': [
    {
      url: '/service/faq/ausland/ausland-roaming/roaming-rechner',
      text: 'Roaming Rechner'
    },
    {
      url: '/service/faq/vertrag/rechnung',
      text: 'Rechnung'
    },
    {
      url: '/service/faq/technik/handy-ortung',
      text: 'Handyortung'
    },
    {
      url: '/service/faq/technik/mailbox',
      text: 'Mailbox'
    },
    {
      url: '/service/faq/sim-karte/sim-karte-sperren',
      text: 'SIM sperren'
    },
    {
      url: '/service/fonic-app',
      text: 'App'
    },
    {
      url: '/service/kontakt',
      text: 'Kontakt'
    }
  ]
}

export default SEOLinks
